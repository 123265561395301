$primary-color: #f64c72;

.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 12%;
    left: auto;
    margin: auto;

      svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(30deg) !important;
      }

    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      transform: rotateZ(30deg) !important;
      z-index: 1;
    }
  }

  .svg-container {
    stroke: $primary-color;
    stroke-width: 10;
  }


@media screen and (max-width: 1150px){
  .logo-container {
    width: 320px;
    height: 609px;
    left: 63%;
    bottom: 2%;
  }
}


@media screen and (max-width: 500px){
  .logo-container {
    width: 120px;
    height: 309px;
    left: 63%;
    bottom: 2%;
  }
}