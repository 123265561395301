$primary-color: #f64c72;

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: $primary-color;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: $primary-color;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: $primary-color;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid $primary-color;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: $primary-color;
      color: #333;
    }
  }
}


@media screen and (max-width: 500px) {
  .home-page {
    .text-zone {
      left: 17%;
      top: 50%;
    }

    h1 {
      font-size:39px;
      line-height: 43px;

      &:before {
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.6;
      }
  
      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: $primary-color;
        position:relative;
        font-size: 18px;
        position: absolute;
        margin-top: 38px;
        margin-left: 2px;
      }

      img {
        margin-left: 10px;
        width: 22px;
      }
    }

    .flat-button {
      color: $primary-color;
      font-size: 9px;
      font-weight: 400;
      padding: 5px 12px;
    }
  }
}
